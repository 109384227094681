import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const ExhibitionPostTemplate = ({
  content,
  contentComponent,
  featuredimage,
  startdate,
  enddate,
  pressrelease,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section-post">
      
      <section className="page-header">
        <div className="container">
          <div className="content subheaderlinks">
            <Link to="/exhibitions" className="post-header-link">
              <span className="breadcrumbs-links">
                Exhibitions
              </span>
            </Link>
            <span className="spacing-item">/</span>
            <span className="breadcrumbs-links">
              {title}
            </span>
          </div>
        </div>
      </section>
      {helmet || ''}

      <div className="container">
      
        <div className="post-hero-image">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `featured image thumbnail for post ${title}`,
              }}
            />
        </div>
      
        <div className="column">
          <div className="column is-12">
            <div className="content">
              <div className="container content exhibition-post">
                  <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                    {title}
                  </h1>
                  <span className="subtitle is-size-5 is-block">
                    {startdate} – {enddate}
                  </span>
                  <br />
                  <PostContent content={content} />
                  <br />
                  {pressrelease ? (<p><a href={`/img/${pressrelease}`} className="sociallinks" target="_blank">Press Release</a></p>) : null}
                  <p>{tags && tags.length ? (
                    <div>
                      <hr />
                      <h4>Tags</h4>
                      <ul className="taglist">
                        {tags.map((tag) => (
                          <li key={tag + `tag`}>
                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  </p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </section>
  )
}

ExhibitionPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  pressrelease: PropTypes.string,
  helmet: PropTypes.object,
}

const ExhibitionPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ExhibitionPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        featuredimage={post.frontmatter.featuredimage}
        pressrelease={post.frontmatter.pressrelease}
        startdate={post.frontmatter.startdate}
        enddate={post.frontmatter.enddate}
        helmet={
          <Helmet titleTemplate="%s | Exhibition">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

ExhibitionPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ExhibitionPost

export const pageQuery = graphql`
  query ExhibitionPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        startdate(formatString: "MMM DD, YYYY")
        enddate(formatString: "MMM DD, YYYY")
        title
        description
        pressrelease
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 520, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
    }
  }
`
